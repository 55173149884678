import React from 'react'
import { graphql } from 'gatsby'

import SEO from '../components/seo'
import Layout from '../components/layout'

export default ({ data }) => (
  <>
    <SEO />
    <Layout>
      <h1>Now</h1>
      
      <p>
        Coming soon :)
      </p>
    </Layout>
  </>
)